import React from "react";
import { withRouter } from "react-router-dom";
import {
  withStyles,
  FormControl,
  LinearProgress,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { getNextMonday } from "../programs/utils";
import { adjustMaxs } from "../../utils/helpers";
import { Auth } from "aws-amplify";
import { registerAndBuild } from "./api";
import wai_icon from "../../assets/1024.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FadeIn from "react-fade-in";

// Form Step Components
import LifterInfo from "./components/1LifterInfo";
import MaxLifts from "./components/3MaxLifts";
import ChooseWeeks from "./components/5ChooseWeeks";
import OnboardingFormSubmit from "./components/6OnboardingFormSubmit";
import OnboardingPayment from "./components/7OnboardingPayment";
import UserReport from "./components/userReport";

import { styles } from "./styles";

import { connect } from "react-redux";
import { updateUserData, fetchUserData } from "../../state/thunk";
import { toggleLoading } from "../../state/actions";
import { getTranslate } from "react-localize-redux";
import mixpanel from "mixpanel-browser";

let fadeDelay = 400; // sets the delay in prevStep and nextStep

class Onboarding extends React.Component {
  optimal_ratios = {
    Sn_CJ: 0.83,
    back_squat_clean: 1.36,
    strict_press_jerk: 0.55,
    power_snatch_snatch: 0.83,
    power_clean_clean: 0.83,
    jerk_clean: 1.02,
  };

  state = {
    length_days: 0,
    step: 1,
    totalNumSteps: 8,
    cognito_id: "",
    username: "",
    stripeUrl: "",
    frequency: "4",
    birthday: "",
    male: true,
    bodyweight: "",
    height: "",
    comp_styles: {
      squat: "low-bar back squat",
      bench: "medium-grip bench press",
      deadlift: "sumo deadlift",
      jerk: "split_jerk",
    },
    maxs: {
      snatch: {
        max: 0,
        xrm: 1,
        unknown: false,
        max_multipliers: { initial: 1 },
      },
      clean: {
        max: 0,
        xrm: 1,
        unknown: false,
        max_multipliers: { initial: 1 },
      },
      split_jerk: {
        max: 0,
        xrm: 1,
        unknown: false,
        max_multipliers: { initial: 1 },
      },
      back_squat: {
        max: 0,
        xrm: 1,
        unknown: false,
        max_multipliers: { initial: 1 },
      },
      power_jerk: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      push_press: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      power_snatch: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      hang_snatch_below_knee: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      block_above_knee_snatch: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      power_clean: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      hang_clean_below_knee: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      block_above_knee_clean: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      front_squat: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      overhead_squat: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
      strict_press: {
        max: 0,
        xrm: 1,
        unknown: true,
        max_multipliers: { initial: 1 },
      },
    },
    start_date: "",
    ranges: null,
    promoCode: "",
    plError: "",
    pbError: "",
    readiness_level: "",
    fitness_level: "",
    birth_day: "1",
    birth_month: "1",
    birth_year: "2000",
    loading: false,
    errors: [],
    entrymin: 0,
    entrymax: 999,
    metric: true,
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    loggedIn: false,
    payment_method: null,
    payTabToDisplay: "CREDITCARD",
    registration: true,
    program: "weightlifting",
    verified: false,
    verificationSent: false,
    termsAccepted: true,
    fadeVisible: true,
    workout_days: ["monday", "wednesday", "friday"],
    max_jerk: 0,
    start_date_type: "monday",
    ratio_tag: "",
    ratio_tag_back_cl: "",
    ratio_tag_strict_press_jerk: "",
    ratio_tag_power_snatch_snatch: "",
    ratio_tag_power_clean_clean: "",
    ratio_tag_jerk_clean: "",
    cj_min: 0,
    test: 0,
    program_focus: "Competition",
    sessions_info: {
      snatch: 0,
      clean_jerk: 0,
      squat: 0,
      pull: 0,
      press: 0,
      accessory: 0,
    },
    volume_info: {
      snatch: 0,
      clean_jerk: 0,
      squat: 0,
      pull: 0,
      press: 0,
      accessory: 0,
    },
  };

  getDifferenceInDays(startDate, endDate) {
    // Create Date objects for the start and end dates
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds = endDateObj - startDateObj;

    // Convert milliseconds to days (1 day = 86400000 milliseconds)
    const differenceInDays = Math.floor(differenceInMilliseconds / 86400000);

    return differenceInDays;
  }

  async componentDidMount() {
    const { userData = null, fetchUserData } = this.props;
    mixpanel.track(`max lifts screen: snatch`);

    const urlParams = new URLSearchParams(window.location.search);
    let param_access_code = urlParams.get("accessCode");

    let loggedIn = false;

    await Auth.currentSession()
      .then((sessionRes) => {
        console.log("sessionRes", sessionRes);
        loggedIn = true;
      })
      .catch((e) => {
        console.log("not logged in");
      });

    if (loggedIn) {
      await fetchUserData(userData.userName).then((data) => {
        // console.log('onboarding data:', data)
        var {
          metric,
          maxs,
          userName,
          cognito_id,
          birthday,
          height,
          bodyweight,
          comp_styles,
          male,
        } = data;

        if (!metric) {
          // console.log('the metric is false. Adjusting maxs:', maxs)
          bodyweight = bodyweight * 2.20462;
          maxs = adjustMaxs(maxs, !metric);
        }

        // console.log('loading bodyweight:', bodyweight)

        // in case of corrupted profile
        if (birthday === null) {
          birthday = "2000-1-1";
        }
        if (male === null) {
          male = true;
        }

        let dates = getNextMonday();

        let temp_length_days =
          this.getDifferenceInDays(dates.start_date, dates.end_date) + 1;

        var state = {
          username: userName,
          cognito_id: cognito_id,
          birthday: birthday,
          start_date: dates.start_date,
          end_date: dates.end_date,
          length_days: temp_length_days,
          maxs: maxs,
          comp_styles,
          metric: true,
          male,
          height: height,
          bodyweight: bodyweight,
          birth_day: birthday
            ? parseInt(birthday.split(" ")[0].split("-")[2])
            : 1,
          birth_month: birthday
            ? parseInt(birthday.split(" ")[0].split("-")[1])
            : 1,
          birth_year: birthday
            ? parseInt(birthday.split(" ")[0].split("-")[0])
            : 2000,
          loggedIn: true,
          metric,
        };

        state.state_copy = state;
        this.setState(state);
      });
    } else {
      var state = this.state;
      state.state_copy = state;
      let dates = getNextMonday();
      state.start_date = dates.start_date;
      state.end_date = dates.end_date;
      state.length_days =
        this.getDifferenceInDays(dates.start_date, dates.end_date) + 1;
      state.promoCode = param_access_code ? param_access_code : "";
      this.setState(state);
    }

    // console.log('state after componentDidMount:', this.state)
  }

  masterValidator(step) {
    switch (step) {
      case 1:
        return !["", "0", 0].includes(this.state.maxs["snatch"].max);
      case 2:
        return !["", "0", 0].includes(this.state.maxs["clean"].max);
      case 3:
        return !["", "0", 0].includes(this.state.maxs["jerk"].max);
      case 4:
        return !["", "0", 0].includes(this.state.maxs["back_squat"].max);
      case 5:
        return (
          this.state.height !== "" &&
          this.state.bodyweight !== "" &&
          this.state.fitness_level !== ""
        );
      case 7:
        return this.state.length_days > 28 && this.state.length_days < 141
          ? true
          : false;
      case 8:
        if (
          this.state.firstName === "" ||
          this.state.lastName == "" ||
          this.state.email === "" ||
          !this.state.email.includes("@") ||
          this.state.password.length < 6
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  handleError = (error) => {
    if (typeof error === "object" && error.message) {
      error = error.message;
    } else if (typeof error === "object") {
      error = JSON.stringify(error);
    }
    this.setState({
      loading: false,
      plError: error,
    });
  };

  handleValidationError = (errors) => {
    let plError;

    if (errors.includes("termsAccepted")) {
      plError = `Please accept terms to continue.`;
    } else {
      plError = `${this.props.translate("pleaseFillInData")} ${errors}`;
    }

    this.setState({
      errors,
      plError,
      loading: false,
    });
  };

  calculate_ratios() {
    this.state.max_jerk = Math.max(
      this.state.maxs.power_jerk.max,
      this.state.maxs.split_jerk.max
    );
    this.state.cj_min = Math.min(
      this.state.maxs.clean.max,
      this.state.max_jerk
    );

    let difference =
      this.optimal_ratios.Sn_CJ -
      this.state.maxs.snatch.max / this.state.cj_min;
    this.state.ratio_tag =
      difference > 0.075
        ? "Much stronger at Clean & Jerk than Snatch"
        : difference > 0.025
        ? "Stronger at Clean & Jerk than Snatch"
        : difference < -0.025
        ? "Stronger at Snatch than Clean & Jerk"
        : difference < -0.075
        ? "Much stronger at Snatch than Clean & Jerk"
        : "Balanced Snatch and Clean & Jerk";

    difference =
      this.optimal_ratios.back_squat_clean -
      this.state.maxs.back_squat.max / this.state.maxs.clean.max;
    this.state.ratio_tag_back_cl =
      difference > 0.075
        ? "Much stronger at Clean than Back Squat"
        : difference > 0.025
        ? "Stronger at Clean than Back Squat"
        : difference < -0.025
        ? "Stronger at Back Squat than Clean"
        : difference < -0.075
        ? "Much stronger at Back Squat than Clean"
        : "Balanced Back Squat and Clean";

    difference =
      this.optimal_ratios.strict_press_jerk -
      this.state.maxs.strict_press.max /
        Math.max(
          this.state.maxs.power_jerk.max,
          this.state.maxs.split_jerk.max
        );
    this.state.ratio_tag_strict_press_jerk =
      difference > 0.075
        ? "Much stronger at Jerk than Strict Press"
        : difference > 0.025
        ? "Stronger at Jerk than Strict Press"
        : difference < -0.025
        ? "Stronger at Strict Press than Jerk"
        : difference < -0.075
        ? "Much stronger at Strict Press than Jerk"
        : "Balanced Strict Press and Jerk";

    difference =
      this.optimal_ratios.power_snatch_snatch -
      this.state.maxs.snatch.max / this.state.maxs.power_snatch.max;
    this.state.ratio_tag_power_snatch_snatch =
      difference > 0.075
        ? "Much stronger at Snatch than Power Snatch"
        : difference > 0.025
        ? "Stronger at Snatch than Power Snatch"
        : difference < -0.025
        ? "Stronger at Power Snatch than Snatch"
        : difference < -0.075
        ? "Much stronger at Power Snatch than Snatch"
        : "Balanced Power Snatch and Snatch";

    difference =
      this.optimal_ratios.power_clean_clean -
      this.state.maxs.power_clean.max / this.state.maxs.clean.max;
    this.state.ratio_tag_power_clean_clean =
      difference > 0.075
        ? "Much stronger at Clean than Power Clean"
        : difference > 0.025
        ? "Stronger at Clean than Power Clean"
        : difference < -0.025
        ? "Stronger at Power Clean than Clean"
        : difference < -0.075
        ? "Much stronger at Power Clean than Clean"
        : "Balanced Power Clean and Clean";

    difference =
      this.optimal_ratios.jerk_clean -
      this.state.max_jerk / this.state.maxs.clean.max;
    this.state.ratio_tag_jerk_clean =
      difference > 0.075
        ? "Much stronger at Jerk than Clean"
        : difference > 0.025
        ? "Stronger at Jerk than Clean"
        : difference < -0.025
        ? "Stronger at Clean than Jerk"
        : difference < -0.075
        ? "Much stronger at Clean than Jerk"
        : "Balanced Jerk and Clean";
  }

  toggleLoading = () => {
    this.setState({ loading: !this.state.loading });
  };

  nextStep = async () => {
    const { step } = this.state;

    if (step === 5) {
      this.calculate_ratios();
    }

    if (this.masterValidator(step)) {
      if (step === 8) {
        console.log("this is running registration and logging the user in");
        await registerAndBuild(this);
      } else {
        this.setState({ fadeVisible: false });
        await new Promise((r) => setTimeout(r, fadeDelay));

        // handling max lifts tracking here
        step === 1 && mixpanel.track(`max lifts screen: clean`);
        step === 2 && mixpanel.track(`max lifts screen: jerk`);
        step === 3 && mixpanel.track(`max lifts screen: squat`);

        this.setState({
          step: step + 1,
          plError: "",
        });

        await new Promise((r) => setTimeout(r, fadeDelay));
        this.setState({ fadeVisible: true });
      }
    } else {
      console.log("step", step, "failed validation");

      if ([1, 2, 3, 4, 7].includes(step)) {
        if (step === 7) {
          if (this.state.length_days < 29) {
            this.handleError("Your program is less than 29 days, or 4 weeks");
          } else {
            this.handleError(
              "Your program is greater than 141 days, or 20 weeks"
            );
          }
        } else {
          this.handleError(`Please enter required lifts!`);
        }
      } else if (step === 5) {
        if (this.state.height === "" || this.state.bodyweight === "") {
          this.handleError(`Please enter height and weight.`);
        } else {
          this.handleError(`Please select readiness level.`);
        }
      } else {
        this.handleError(`Please fill out all fields properly.`);
      }
    }
  };

  prevStep = async () => {
    const { step } = this.state;
    console.log("prevStep from step:", step);

    this.setState({ fadeVisible: false });
    await new Promise((r) => setTimeout(r, fadeDelay));

    if (step > 1) {
      this.setState({ step: step - 1 });
    } else {
      this.props.history.goBack();
    }

    await new Promise((r) => setTimeout(r, fadeDelay));
    this.setState({ fadeVisible: true });
  };

  handleChange = (e) => {
    const { value, name } = e.target;

    this.setState({ [name]: value });
  };

  shouldDisplayButton = () => {
    const step = this.state.step;
    if (step === 9 || (step === 8 && this.state.loggedIn)) {
      return false;
    } else {
      return true;
    }
  };

  getLinearProgress = (classes, step, totalNumSteps) => {
    return (
      <div className={classes.progressBarContainer}>
        <LinearProgress
          className={classes.progressBar}
          variant="determinate"
          value={(step / totalNumSteps) * 100}
        />
      </div>
    );
  };

  getFormComponent = (step, classes) => {
    let formComponent;

    if (step === 1) {
      formComponent = (
        <MaxLifts
          classes={classes}
          props={this}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          selectedLifts={[
            "snatch",
            "power_snatch",
            "hang_snatch_below_knee",
            "block_above_knee_snatch",
          ]}
        />
      );
    } else if (step === 2) {
      formComponent = (
        <MaxLifts
          classes={classes}
          props={this}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          selectedLifts={[
            "clean",
            "power_clean",
            "hang_clean_below_knee",
            "block_above_knee_clean",
          ]}
        />
      );
    } else if (step === 3) {
      // mixpanel.track(`max lifts screen: jerk`);
      formComponent = (
        <MaxLifts
          classes={classes}
          props={this}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          selectedLifts={[
            "jerk",
            "split_jerk",
            "power_jerk",
            "push_press",
            "strict_press",
          ]}
        />
      );
    } else if (step === 4) {
      // mixpanel.track(`max lifts screen: squat`);
      formComponent = (
        <MaxLifts
          classes={classes}
          props={this}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          selectedLifts={["back_squat", "front_squat", "overhead_squat"]}
        />
      );
    } else if (step === 5) {
      formComponent = (
        <LifterInfo
          classes={classes}
          props={this}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
        />
      );
    } else if (step === 6) {
      formComponent = (
        <UserReport
          classes={classes}
          parent={this}
          props={this}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
        />
      );
    } else if (step === 7) {
      formComponent = (
        <ChooseWeeks
          classes={classes}
          parent={this}
          props={this}
          nextStep={this.nextStep}
          prevStep={this.prevStep}
        />
      );
    } else if (step === 8) {
      console.log(this.state);
      formComponent = (
        <OnboardingFormSubmit
          classes={classes}
          parent={this}
          parentState={this.state}
          handleChange={this.handleChange}
          handleError={this.handleError}
          handleValidationError={this.handleValidationError}
          toggleLoading={this.toggleLoading}
          prevStep={this.prevStep}
        />
      );
    } else if (step === 9) {
      formComponent = (
        <OnboardingPayment
          classes={classes}
          parent={this}
          parentState={this.state}
          handleChange={this.handleChange}
          handleError={this.handleError}
          handleValidationError={this.handleValidationError}
          toggleLoading={this.toggleLoading}
          prevStep={this.prevStep}
        />
      );
    }

    return formComponent;
  };

  getErrorDiv = (plError, classes) => {
    if (plError) {
      return <div className={classes.errorDiv}>{plError}</div>;
    }
  };

  getButton = (classes, nxtBtn, btnText, plError) => {
    return (
      <div className={classes.formButtonSmallContainer}>
        {this.getErrorDiv(plError, classes)}
        <Button
          className={classes.formButtonSmall + " " + nxtBtn}
          onClick={this.nextStep}
          name="next"
        >
          {this.state.loading ? (
            <CircularProgress
              size={25}
              classes={{ circle: classes.circularProgressPrimaryColor }}
            />
          ) : (
            this.props.translate(btnText)
          )}
        </Button>
      </div>
    );
  };

  getExplainText = () => {
    let step = this.state.step;
    let translate = this.props.translate;
    if (step === 1) {
      return translate("onboardLiftsSnatch");
    } else if (step === 2) {
      return translate("onboardLiftsClean");
    } else if (step === 3) {
      return translate("onboardLiftsJerk");
    } else if (step === 4) {
      return translate("onboardLiftsSquat");
    } else if (step === 5) {
      return translate("onboardBasicInfo");
    } else if (step === 6) {
      return "The ratios between certain lifts help to inform the AI of potential areas of weakness or technical limitations that it should focus on during your training.";
    } else if (step === 7) {
      return translate("onboardSelectWeeks");
    } else if (step === 8) {
      return translate(
        this.state.loggedIn ? "onboardBuild" : "onboardRegister"
      );
    } else if (step === 9) {
      return (
        <div style={{ textAlign: "center" }}>
          <div style={{ paddingBottom: 10 }}>
            Great! That's all the information we need for now. You are ready to
            join us in the future of weightlifting training.
          </div>
        </div>
      );
    } else {
      return translate("onboardBasicInfo");
    }
  };

  getIconAndExplain = (classes) => {
    return (
      <div className={classes.formStepContent}>
        <div className={classes.explainMessage}>
          <div className={classes.iconContainer}>
            <img className={classes.iconObject} src={wai_icon} alt="" />
          </div>
          {this.getExplainText()}
        </div>
      </div>
    );
  };

  getBackButton = (classes) => {
    return (
      <div className={classes.backPressContainer}>
        <Button onClick={() => this.prevStep()}>
          <ArrowBackIcon fontSize={"large"}></ArrowBackIcon>
        </Button>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { step, totalNumSteps, plError } = this.state;

    let formComponent = this.getFormComponent(step, classes);

    const btnText = "next";
    const nxtBtn = classes.nextBtnWH;

    let shouldDisplayButton = this.shouldDisplayButton();

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {this.getBackButton(classes)}
        {this.getLinearProgress(classes, step, totalNumSteps)}
        <FadeIn visible={this.state.fadeVisible}>
          {this.getIconAndExplain(classes)}
          <div className={classes.stepFormContainer}>
            <FormControl
              fullWidth={true}
              className={classes.formControlContainer}
            >
              {formComponent}
            </FormControl>
          </div>
        </FadeIn>
        {shouldDisplayButton &&
          this.getButton(classes, nxtBtn, btnText, plError)}
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, localize }) => ({
  userData: userReducer,
  translate: getTranslate(localize),
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (pl) => dispatch(updateUserData(pl)),
  fetchUserData: (userName) => dispatch(fetchUserData(userName)),
  toggleLoading: () => dispatch(toggleLoading()),
});

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Onboarding))
);
